
<div>
  <transition name="fade" mode="out-in">
    <div v-if="productFilterLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="productFilterError" class="alert alert-danger">
      <template v-if="productFilterError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else class="row">
      <!-- Form -->
      <div class="col-sm-12">
        <form @submit.prevent="submit">
          <div class="form-group row">
            <label for="input-name" class="col-sm-2 text-break col-form-label">{{ $t('attributes.productFilter.name') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <input v-model="productFilter.name" type="text" class="form-control" :class="{ 'is-invalid' : productFilterSubmitErrors && productFilterSubmitErrors.name }" id="input-name" :placeholder="$t('attributes.productFilter.name')">
              <div v-if="productFilterSubmitErrors && productFilterSubmitErrors.name" class="invalid-feedback">{{ tErrors('productFilter', 'name', productFilterSubmitErrors.name) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="multiselect-groups" class="col-sm-2 col-form-label">{{ $t('attributes.productFilter.group') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <group-modal-select
                v-model="productFilter.group"
                :class="{ 'is-invalid' : productFilterSubmitErrors && productFilterSubmitErrors.group }"
                id="multiselect-group"
              />
              <div v-if="productFilterSubmitErrors && productFilterSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('productFilter', 'group', productFilterSubmitErrors.group) }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ $t('attributes.productFilter.filterValues') }}</label>
            <div class="col-lg-10 col-12">
              <div class="mb-3 d-flex justify-content-between">
                <div />

                <button type="button" class="btn btn-success" @click="newFilterValue()">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                  {{ $t('shared.actions.newFilterValue') }}
                </button>
              </div>

              <!-- Filter values list -->
              <div v-if="productFilter.filterValues.length === 0" class="alert alert-warning">
                {{ $t('shared.warnings.noFilterValue') }}
              </div>
              <table v-else class="table table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th class="th-shrink">{{ $t('attributes.filterValue.id') }}</th>
                    <th>{{ $t('attributes.filterValue.value') }}</th>
                    <th>{{ $t('attributes.filterValue.productsCount') }}</th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                    <th class="th-shrink"></th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  v-model="productFilter.filterValues"
                  handle=".handle"
                >
                  <tr
                    v-for="filterValue in productFilter.filterValues"
                    :key="filterValue.id"
                    :class="{ 'table-danger': filterValue._destroy }"
                  >
                    <!-- Drag & drop handle -->
                    <th class="table-col-shrink text-center handle cursor-move">
                      <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                    </th>

                    <th>{{ filterValue.id }}</th>
                    <td>{{ filterValue.value }}</td>
                    <td>{{ filterValue.products.length }}</td>
                    <td>
                      <template v-if="!filterValue.id">
                        <span class="badge badge-warning">New</span>
                      </template>
                    </td>
                    <td class="text-center">
                      <template v-if="filterValueHasError(filterValue)">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 text-danger"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
                      </template>
                    </td>
                    <td class="text-nowrap">
                      <div class="gutter-x-xs">
                        <button type="button" class="btn btn-sm btn-warning" @click="editFilterValue(filterValue)">
                          {{ $t('shared.actions.edit') }}
                        </button>
                        <template v-if="!filterValue._destroy">
                          <button type="button" class="btn btn-sm btn-danger" @click="deleteFilterValue(filterValue)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-sm btn-success" @click="restoreFilterValue(filterValue)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>

          <!-- Filter value modal -->
          <b-modal
            id="filterValueModal"
            ref="filterValueModal"
            size="xl"
            :title="$t(`shared.actions.${filterValueModalAction}FilterValue`)"
            @ok="filterValueModalOk"
            @shown="$refs.modalFilterValueValueInput.focus()"
            no-fade
          >
            <form @submit.stop.prevent="filterValueModalSubmit()">
              <div class="form-group row">
                <label for="input-filter-value-value" class="col-sm-2 col-form-label">{{ $t('attributes.filterValue.value') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <input
                    v-model="filterValueModal.value"
                    type="text"
                    class="form-control"
                    id="input-filter-value-value"
                    ref="modalFilterValueValueInput"
                    :placeholder="$t('attributes.filterValue.value')"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label for="filter-value-products" class="col-sm-2">{{ $t('attributes.filterValue.products') }}</label>
                <div class="col-lg-10 col-12">
                  <div class="d-flex justify-content-between">
                    <div />

                    <product-modal-select
                      v-if="productFilter.group"
                      :group-id="productFilter.group.id"
                      v-model="filterValueModal.products"
                      multiple
                    >
                      <template v-slot:element>
                        <button type="button" class="btn btn-success mb-3">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
                          {{ $t('views.michelinOffers.form.addProduct') }}
                        </button>
                      </template>
                    </product-modal-select>
                  </div>

                  <div v-if="filterValueModal.products.length === 0" class="alert alert-warning" role="alert">
                    {{ $t('shared.warnings.noProduct') }}
                  </div>
                  <table v-else class="table table-hover">
                    <thead>
                      <tr>
                        <th class="th-shrink">{{ $t('attributes.product.id') }}</th>
                        <th class="th-shrink">{{ $t('attributes.product.img') }}</th>
                        <th>{{ $t('attributes.productLanguageData.name') }}</th>
                        <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                        <th>{{ $t('attributes.product.ean') }}</th>
                        <th>{{ $t('attributes.product.upc') }}</th>
                        <th class="th-shrink"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in filterValueModal.products" :key="product.id">
                        <th>{{ product.id }}</th>
                        <td>
                          <img
                            v-if="product.img"
                            :src="product.img.url"
                            class="filter-value-product-img"
                          />
                        </td>
                        <td>{{ product.productLanguageDatas[0].name }}</td>
                        <td>{{ product.productLanguageDatas[0].rawPackaging }}</td>
                        <td>{{ product.ean }}</td>
                        <td>{{ product.upc }}</td>
                        <td class="text-nowrap">
                          <button type="button" class="btn btn-sm btn-danger" @click="removeProductFromFilterValue(product)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </b-modal>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="productFilterSubmitLoading">
              <md-spinner v-if="productFilterSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</div>
